<template>
  <div class="workplace-wrapper">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.workplace-wrapper {
  height: 100%;
  min-height: 100%;
}
</style>